import { Col, Row } from 'antd';

import { MinhasContribuicoes } from './minhasContribuicoes';
import { Saldo } from './saldo';

import styles from './index.module.scss';

export const Dashboard: React.FC<{}> = () => {
	return (
		<>
			<Row className={styles.row}>
				<Col lg={8} className={styles.col}>
					<Saldo />
				</Col>
			</Row>
			<Row className={styles.row}>
				<Col lg={8} className={styles.col}>
					<MinhasContribuicoes />
				</Col>
			</Row>
		</>
	);
};
