import { Space } from 'antd';
import { ContentLayout, DrawerBar, DrawerBarItem, ISelectProps, Icons, Select, Typography } from 'jcm-design-system';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { ToggleThemeIconButton, UserAvatar } from '.';

export const LayoutParticipante: React.FC<{ id?: string }> = ({ id }) => {
	const basePath = '/participante';

	useEffect(() => {
		document.title = 'Portal do Participante';
	}, []);

	const drawerBarItems: DrawerBarItem[] = [
		{ label: 'Dashboard', key: basePath, icon: <Icons.Home variant='outlined' /> },
		{ label: 'Saldo', key: `${basePath}/saldo`, icon: <Icons.Paid variant='outlined' /> },
		{
			label: 'Contribuição',
			key: `${basePath}/contribuicao`,
			icon: <Icons.PriceChange variant='outlined' />,
		},
		{
			label: 'Rentabilidade',
			key: `${basePath}/rentabilidade`,
			icon: <Icons.Savings variant='outlined' />,
			disabled: true,
		},
		{
			label: 'Portabilidade',
			key: `${basePath}/portabilidade`,
			icon: <Icons.CompareArrows variant='outlined' />,
			disabled: true,
		},
		{ label: 'Benefício', key: `${basePath}/beneficio`, icon: <Icons.Payments variant='outlined' />, disabled: true },
		{
			label: 'Simulação',
			key: `${basePath}/simulacao`,
			icon: <Icons.BrowseActivity variant='outlined' />,
			disabled: true,
		},
		{
			label: 'Recadastramento',
			key: `${basePath}/recadastramento`,
			icon: <Icons.ContractEdit variant='outlined' />,
			disabled: true,
		},
		{
			label: 'Empréstimo',
			key: `${basePath}/emprestimo`,
			icon: <Icons.AccountBalance variant='outlined' />,
			disabled: true,
		},
		{ label: 'SAC', key: `${basePath}/sac`, icon: <Icons.Help variant='outlined' />, disabled: true },
	];

	const selectOptions: ISelectProps['options'] = [];

	const headerContent = (
		<>
			<Space direction='vertical' style={{ rowGap: '4px', flexGrow: '0.75' }}>
				<Select
					id='plano-select'
					variant='filled'
					options={selectOptions}
					label={
						<Typography.Title size='medium' variant='primary'>
							Plano
						</Typography.Title>
					}
				/>
			</Space>

			<Space direction='vertical' style={{ rowGap: '4px' }}>
				<Typography.Title size='medium' variant='primary'>
					Matrícula
				</Typography.Title>
				<Typography.Body size='large' variant='default'>
					Matrícula
				</Typography.Body>
			</Space>

			<Space direction='vertical' style={{ rowGap: '4px' }}>
				<Typography.Title size='medium' variant='primary'>
					Data de adesão
				</Typography.Title>
				<Typography.Body size='large' variant='default'>
					dd/mm/yyyy
				</Typography.Body>
			</Space>

			<Space direction='vertical' style={{ rowGap: '4px' }}>
				<Typography.Title size='medium' variant='primary'>
					Data base
				</Typography.Title>
				<Typography.Body size='large' variant='default'>
					dd/mm/yyyy
				</Typography.Body>
			</Space>

			<Space direction='vertical' style={{ rowGap: '4px' }}>
				<Typography.Title size='medium' variant='primary'>
					Status
				</Typography.Title>
				<Typography.Body size='large' variant='default'>
					Ativo
				</Typography.Body>
			</Space>

			<Space direction='vertical' style={{ rowGap: '4px' }}>
				<Typography.Title size='medium' variant='primary'>
					Perfil
				</Typography.Title>
				<Typography.Body size='large' variant='default'>
					Conservador
				</Typography.Body>
			</Space>
		</>
	);

	return (
		<ContentLayout
			id={id}
			sider={<DrawerBar items={drawerBarItems} />}
			headerProps={{
				icons: (
					<>
						<ToggleThemeIconButton />
						<UserAvatar />
					</>
				),
				content: headerContent,
			}}
		>
			<Outlet />
		</ContentLayout>
	);
};
