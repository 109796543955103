import { Col, Row, Space } from 'antd';
import { Container, Typography } from 'jcm-design-system';

interface IDashboardCardProps {
	id?: string;
	icon?: React.ReactNode;
	title?: React.ReactNode;
	addon?: React.ReactNode;
	children?: React.ReactNode;
	onClick?: React.MouseEventHandler;
}

export const DashboardCard: React.FC<IDashboardCardProps> = (props) => {
	return (
		<Container
			elevation='default'
			variant='default'
			level={1}
			style={{ height: '100%', cursor: props.onClick ? 'pointer' : 'initial' }}
			id={props.id}
			onClick={props.onClick}
		>
			<Row style={{ flexDirection: 'column', justifyContent: 'space-between', height: '100%', gap: '1rem' }}>
				{(!!props.icon || !!props.title || !!props.addon) && (
					<Row>
						<Col flex='auto'>
							<Space style={{ columnGap: '0.25rem' }}>
								{props.icon}
								<Typography.Headline size='small' variant='default'>
									{props.title}
								</Typography.Headline>
							</Space>
						</Col>

						{props.addon && <Col>{props.addon}</Col>}
					</Row>
				)}

				{props.children}
			</Row>
		</Container>
	);
};
