import { Space } from 'antd';
import { ContentLayout, DrawerBar, DrawerBarItem, ISelectProps, Icons, Select, Typography } from 'jcm-design-system';
import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { ToggleThemeIconButton, UserAvatar } from '.';

export const LayoutAdmin: React.FC<{}> = () => {
	const basePath = '/admin';

	useEffect(() => {
		document.title = 'Portal do Administrador';
	}, []);

	const drawerBarItems = useMemo<DrawerBarItem[]>(() => {
		return [
			{ label: 'Dashboard', key: basePath, icon: <Icons.Home variant='outlined' /> },
			{ label: 'Saldo', key: `${basePath}/saldo`, icon: <Icons.Paid variant='outlined' /> },
			{ label: 'Contribuição', key: `${basePath}/contribuicao`, icon: <Icons.PriceChange variant='outlined' /> },
			{ label: 'Rentabilidade', key: `${basePath}/rentabilidade`, icon: <Icons.Savings variant='outlined' /> },
			{ label: 'Portabilidade', key: `${basePath}/portabilidade`, icon: <Icons.CompareArrows variant='outlined' /> },
			{ label: 'Benefício', key: `${basePath}/beneficio`, icon: <Icons.Payments variant='outlined' /> },
			{ label: 'Simulação', key: `${basePath}/simulacao`, icon: <Icons.BrowseActivity variant='outlined' /> },
			{ label: 'Recadastramento', key: `${basePath}/recadastramento`, icon: <Icons.ContractEdit variant='outlined' /> },
			{ label: 'Empréstimo', key: `${basePath}/emprestimo`, icon: <Icons.AccountBalance variant='outlined' /> },
			{ label: 'SAC', key: `${basePath}/sac`, icon: <Icons.Help variant='outlined' /> },
		];
	}, [basePath]);

	const headerContent = useMemo(() => {
		const selectOptions: ISelectProps['options'] = [];

		return (
			<>
				<Space direction='vertical' style={{ rowGap: '4px', flexGrow: '0.75' }}>
					<Select
						id='plano-select'
						variant='filled'
						options={selectOptions}
						label={
							<Typography.Title size='medium' variant='primary'>
								Plano
							</Typography.Title>
						}
					/>
				</Space>

				<Space direction='vertical' style={{ rowGap: '4px' }}>
					<Typography.Title size='medium' variant='primary'>
						Matrícula
					</Typography.Title>
					<Typography.Body size='large' variant='default'>
						Matrícula
					</Typography.Body>
				</Space>

				<Space direction='vertical' style={{ rowGap: '4px' }}>
					<Typography.Title size='medium' variant='primary'>
						Data de adesão
					</Typography.Title>
					<Typography.Body size='large' variant='default'>
						dd/mm/yyyy
					</Typography.Body>
				</Space>

				<Space direction='vertical' style={{ rowGap: '4px' }}>
					<Typography.Title size='medium' variant='primary'>
						Data base
					</Typography.Title>
					<Typography.Body size='large' variant='default'>
						dd/mm/yyyy
					</Typography.Body>
				</Space>

				<Space direction='vertical' style={{ rowGap: '4px' }}>
					<Typography.Title size='medium' variant='primary'>
						Status
					</Typography.Title>
					<Typography.Body size='large' variant='default'>
						Ativo
					</Typography.Body>
				</Space>

				<Space direction='vertical' style={{ rowGap: '4px' }}>
					<Typography.Title size='medium' variant='primary'>
						Perfil
					</Typography.Title>
					<Typography.Body size='large' variant='default'>
						Conservador
					</Typography.Body>
				</Space>
			</>
		);
	}, []);

	return (
		<ContentLayout
			sider={<DrawerBar items={drawerBarItems} />}
			headerProps={{
				icons: (
					<>
						<ToggleThemeIconButton />
						<UserAvatar />
					</>
				),
				content: headerContent,
			}}
		>
			<Outlet />
		</ContentLayout>
	);
};
