import { AxiosInstance } from 'axios';

import { ParticipanteApi as MockParticipanteApi } from './__mocks__/Api';

// Contribuições

export type ContribuicaoType = { nome: string; valor: number; percentual: number };

export type GetContribuicoesReturnType = {
	contribuicoesParticipante: ContribuicaoType;
	contribuicoesPatrocinadora: ContribuicaoType;
};

export type GetTotaisContribuicoesReturnType = {
	totalContribuicaoUltimoMes: number;
	limiteRestanteBeneficioFiscal: number;
};

// Saldo
type SaldoInfo = { saldo: number; resgate: number };
export type GetSaldoReturnType = {
	total: SaldoInfo;
	participante: SaldoInfo;
	portabilidade: SaldoInfo;
	patrocinadora: SaldoInfo;
};

export interface IParticipanteApi {
	getContribuicoesById: (id: string | number) => Promise<GetContribuicoesReturnType>;
	getTotaisContribuicoesById: (id: string | number) => Promise<GetTotaisContribuicoesReturnType>;
	getIdentidade: () => Promise<any>;
	getSaldo: () => Promise<GetSaldoReturnType>;
}
export class ParticipanteApi implements IParticipanteApi {
	private api: AxiosInstance;
	private mockApi: MockParticipanteApi;
	private isProduction = process.env.NODE_ENV === 'production';
	private clienteId?: number | string;

	private getRequest = async <T extends unknown>(url: string): Promise<T> => {
		// Caso clienteId seja definido, adicionamos à url
		const requestUrl = this.clienteId !== undefined ? `/${this.clienteId}${url}` : url;
		const { data } = await this.api.get<T>(requestUrl);

		// TODO - Lidar com erros
		return data as T;
	};

	constructor(props: { api: AxiosInstance; isProduction?: boolean; clienteId?: number | string }) {
		this.mockApi = new MockParticipanteApi();
		this.api = props.api;

		if (props.isProduction !== undefined) {
			this.isProduction = props.isProduction;
		}

		if (props.clienteId) {
			this.clienteId = props.clienteId;
		}

		return this;
	}

	// TODO - Implementar
	public getIdentidade = async () => {
		// const { data } = await this.api.get<ApiResponse<any>>('/whoami');
		return this.mockApi.getIdentidade();
	};

	// Contribuições
	public getTotaisContribuicoesById = async (id: string | number) => {
		if (!this.isProduction) return this.mockApi.getTotaisContribuicoesById(id);

		return await this.getRequest<GetTotaisContribuicoesReturnType>(`/api/contribuicoes/totais/${id}`);
	};

	public getContribuicoesById = async (id: string | number) => {
		if (!this.isProduction) return this.mockApi.getContribuicoesById(id);

		return await this.getRequest<GetContribuicoesReturnType>(`/api/contribuicoes/${id}`);
	};

	// Saldo
	public getSaldo = async () => {
		if (!this.isProduction) return this.mockApi.getSaldo();

		return await this.getRequest<GetSaldoReturnType>(`/api/saldo`);
	};
}

export interface IAdminApi {}
export class AxiosAdminApi implements IAdminApi {}
