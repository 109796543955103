import Keycloak, { KeycloakProfile } from 'keycloak-js';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useGlobalConfigFile } from '../hooks';

export interface IAuthUser {
	sub: string;
	preferred_username: string;

	email?: string;
	email_verified?: boolean;

	name?: string;
	given_name: string;
	family_name: string;
}

export interface IAuthProfile extends KeycloakProfile {}

export interface IKeycloakContext {
	isLoading: boolean;
	isAuthenticated?: boolean;

	user?: IAuthUser;
	profile?: IAuthProfile;
	idToken?: string;
	accessToken?: string;
	tokenExpires?: Date;

	logout: () => Promise<void>;
}

const KeycloakContext = createContext<IKeycloakContext>(undefined!);

export const useKeycloakContext = () => useContext(KeycloakContext);

type Props = { children: React.ReactNode; admin?: boolean; isProduction?: boolean };
export const KeycloakContextProvider: React.FC<Props> = ({ children, admin, isProduction }) => {
	const { keycloakConfig: kcConfig } = useGlobalConfigFile();

	const keycloakConfig = useMemo(() => {
		const { url, realm, adminRealm, clientId } = kcConfig;
		const config = {
			url: isProduction || process.env.NODE_ENV === 'production' ? url : 'http://localhost:8080/',
			clientId: clientId,
			realm: admin ? adminRealm : realm,
		};
		return config;
	}, [admin, isProduction, kcConfig]);

	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState<IAuthUser>();
	const [profile, setProfile] = useState<IAuthProfile>();

	const keycloak = useMemo(() => new Keycloak(keycloakConfig), [keycloakConfig]);

	const isAuthenticated = useMemo(() => keycloak.authenticated, [keycloak.authenticated]);

	// Inicializa o keycloak
	useEffect(() => {
		if (!isLoading) return;

		try {
			keycloak
				.init({ onLoad: 'login-required' })
				.then(() => setIsLoading(false))
				.catch((err) => console.error(err));
		} catch (err: any) {
			console.error(`AuthContext: ${err?.message}`);
		}
	}, [keycloak, isLoading]);

	// Carrega info do usuário
	useEffect(() => {
		if (isLoading) return;
		if (!isAuthenticated) return;

		try {
			keycloak.loadUserInfo().then((info) => {
				setUser(info as IAuthUser); // Forçando o tipo
			});
		} catch (err) {
			console.error(err);
		}
	}, [keycloak, isLoading, isAuthenticated]);

	// Carrega perfil do usuário
	useEffect(() => {
		if (isLoading) return;

		try {
			keycloak.loadUserProfile().then((profile) => setProfile(profile));
		} catch (err) {
			console.error(err);
		}
	}, [keycloak, isLoading]);

	const idToken = useMemo(() => keycloak.idToken, [keycloak.idToken]);
	const accessToken = useMemo(() => keycloak.token, [keycloak.token]);
	const logout = useCallback(() => {
		setIsLoading(true);
		return keycloak.logout();
	}, [keycloak]);

	const context = { user, profile, isAuthenticated, isLoading, idToken, accessToken, logout };
	return <KeycloakContext.Provider value={context}>{isLoading ? null : children}</KeycloakContext.Provider>;
};
