import { Col, Row, Skeleton, Space } from 'antd';
import { Button, Container, Icons, Typography } from 'jcm-design-system';

import { CurrencyFormatter, useNavigateOnClick } from '../../../../store';
import { useTotaisContribuicoesQuery } from '../../../../store/hooks/queries/participante';

import { DashboardCard } from '../DashboardCard';
import { ErrorCard } from '../ErrorCard';

export const MinhasContribuicoes: React.FC<{}> = () => {
	// TODO - Implementar
	const idParticipacao = 1240;
	const navigateOnClick = useNavigateOnClick();

	const { data, isPending, isError, refetch } = useTotaisContribuicoesQuery(idParticipacao);

	if (isError) return <ErrorCard refetch={refetch} />;

	return (
		<DashboardCard
			icon={<Icons.PriceChange variant='outlined' style={{ fontSize: '1.5rem' }} />}
			title='Minhas contribuições'
			id='minhas-contribuicoes-card'
		>
			<Row>
				<Col>
					<Typography.Title size='medium' variant='default'>
						Total do mês:{' '}
						<Typography.Title size='medium' variant='default'>
							{isPending ? (
								<Skeleton.Input size='small' active />
							) : (
								CurrencyFormatter.toBRL(data.totalContribuicaoUltimoMes)
							)}
						</Typography.Title>
					</Typography.Title>
				</Col>
			</Row>

			<Row style={{ flexGrow: 1 }}>
				<Col flex='auto'>
					<Container variant='tertiary' elevation='default' level={3} style={{ height: '100%' }}>
						<Space direction='vertical' style={{ width: '100%' }}>
							<Typography.Body size='large' variant='default'>
								Quanto falta para:
							</Typography.Body>

							<Typography.Title size='medium' variant='default'>
								Benefício fiscal:{' '}
								<Typography.Title size='medium' variant='default'>
									{isPending ? (
										<Skeleton.Input size='small' active />
									) : (
										CurrencyFormatter.toBRL(data.limiteRestanteBeneficioFiscal)
									)}
								</Typography.Title>
							</Typography.Title>
						</Space>
					</Container>
				</Col>
			</Row>
			<Row justify='space-between'>
				<Col>
					{/* TODO - direcionar pro local correto */}
					<Button variant='default' type='text'>
						Contribuição extraordinária
					</Button>
				</Col>
				<Col>
					<Button
						variant='default'
						type='filled-tonal'
						loading={isPending}
						onClick={(e) => navigateOnClick(e, 'contribuicao')}
					>
						Consultar contribuição
					</Button>
				</Col>
			</Row>
		</DashboardCard>
	);
};
