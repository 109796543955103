import { Avatar, Popover, Space } from 'antd';
import { Button, Card, CircleButton, Icons, Typography, useTheme } from 'jcm-design-system';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useKeycloakContext, useThemeContext } from '../store';

import styles from './index.module.scss';

type AvatarPopoverProps = { children: React.ReactNode };
const AvatarPopover: React.FC<AvatarPopoverProps> = ({ children }) => {
	const navigate = useNavigate();
	const { user, logout } = useKeycloakContext();
	const { colors } = useTheme();

	const content = useMemo(() => {
		return (
			<Space direction='vertical'>
				<Card variant='default' className={styles.card} id='avatar-popover-content-card'>
					<Space direction='vertical' className='w-100'>
						<div className={styles.userInfoContainer}>
							<Avatar className={styles.avatar} size='large'>
								{user?.name
									? user?.name.slice(0, 2).toLocaleUpperCase()
									: user?.preferred_username.slice(0, 2).toLocaleUpperCase()}
							</Avatar>

							<Space direction='vertical' size='small' className={styles.userInfoSpace}>
								<Typography.Body variant='default' size='medium' className={styles.userName}>
									{user?.name ?? user?.preferred_username ?? 'Usuário'}
								</Typography.Body>
								<Typography.Body variant='default' size='medium' className={styles.userEmail}>
									{user?.email ?? 'usuario@email.com'}{' '}
								</Typography.Body>
							</Space>
						</div>
					</Space>
				</Card>
				<Button
					type='text'
					variant='default'
					className={styles.logouBtn}
					onClick={() => {
						navigate('/');
						return logout();
					}}
				>
					Sair
				</Button>
			</Space>
		);
	}, [user?.name, user?.email, user?.preferred_username, logout, navigate]);

	return (
		<Popover
			overlayClassName={styles.popover}
			color={colors.containerHigh}
			placement='bottomLeft'
			trigger='click'
			arrow={false}
			content={content}
		>
			{children}
		</Popover>
	);
};

export const UserAvatar: React.FC<{ id?: string }> = ({ id }) => {
	const { user } = useKeycloakContext();

	return (
		<AvatarPopover>
			<Avatar size='large' className={styles.avatar}>
				<span id={id}>
					{user?.name
						? user?.name.slice(0, 2).toLocaleUpperCase()
						: user?.preferred_username.slice(0, 2).toLocaleUpperCase()}
				</span>
			</Avatar>
		</AvatarPopover>
	);
};

export const ToggleThemeIconButton: React.FC<{ id?: string }> = ({ id }) => {
	const { currentTheme, toggleTheme } = useThemeContext();

	const icon = useMemo(() => {
		return currentTheme === 'light' ? (
			<Icons.Sunny variant='outlined' fill />
		) : (
			<Icons.DarkMode variant='outlined' fill />
		);
	}, [currentTheme]);

	return <CircleButton variant='default' type='elevated' icon={icon} onClick={toggleTheme} id={id} />;
};

export * from './admin';
export * from './home';
export * from './participante';
