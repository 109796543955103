interface IErrorDetail {
	field: string;
	message: string;
}

export interface IErrorResponse {
	data: null;
	title: string;
	errors: IErrorDetail[] | null;
}

export interface ISuccessResponse<T> {
	data: T;
	title: null;
	errors: null;
}

export type ApiResponseType<T> = IErrorResponse | ISuccessResponse<T>;

/**
 * @deprecated ApiResponse foi removida da API
 */
export class ApiResponse {
	static Success = class<T> implements ISuccessResponse<T> {
		public data: T;
		public title: ISuccessResponse<T>['title'] = null;
		public errors: ISuccessResponse<T>['errors'] = null;

		constructor(props: { data: T }) {
			this.data = props.data;
			return this;
		}
	};

	static Error = class implements IErrorResponse {
		public data = null;
		public title: IErrorResponse['title'];
		public errors: IErrorResponse['errors'];

		constructor(props: Omit<IErrorResponse, 'data'>) {
			this.title = props.title;
			this.errors = props.errors;
			return this;
		}
	};
}
