import { Col, Row, Skeleton, Space } from 'antd';
import { Button, Card, CircleButton, Icons, Typography, VariantType } from 'jcm-design-system';
import { useCallback, useState } from 'react';

import { CurrencyFormatter, useNavigateOnClick } from '../../../../store';
import { useSaldoQuery } from '../../../../store/hooks/queries/participante';

import { DashboardCard } from '../DashboardCard';
import { ErrorCard } from '../ErrorCard';

import styles from './index.module.scss';

const Placeholder = () => {
	return (
		<div className={styles.placeholder}>
			<span>.....</span>
		</div>
	);
};

interface ISaldoCardProps {
	isVisible: boolean;
	isLoading: boolean;
	variant: VariantType;
	title: React.ReactNode;
	icon: React.ReactNode;
	data?: { saldo: number; resgate: number };
}
const SaldoCard: React.FC<ISaldoCardProps> = ({ variant, isLoading, isVisible, data, title, icon }) => {
	return (
		<Card variant={variant} level={0}>
			<Space direction='vertical'>
				<Typography.Body size='large' variant={variant}>
					{icon}
				</Typography.Body>
				<Typography.Body size='large' variant='default'>
					{title}
				</Typography.Body>
				<Typography.Title size='medium' variant={variant}>
					{isLoading ? (
						<Skeleton.Input size='small' active />
					) : (
						<>{isVisible ? CurrencyFormatter.toBRL(data?.saldo) : <>R$ {<Placeholder />}</>}</>
					)}
				</Typography.Title>
				<Typography.Body size='small' variant='default'>
					{isLoading ? null : <>Resgate {isVisible ? data?.resgate : <Placeholder />}%</>}
				</Typography.Body>
			</Space>
		</Card>
	);
};

export const Saldo: React.FC<{}> = () => {
	const [isVisible, setIsVisible] = useState(false);
	const navigateOnClick = useNavigateOnClick();

	const { data, isError, isPending, refetch } = useSaldoQuery();

	const toggleVisibility = useCallback(() => setIsVisible((prev) => !prev), []);

	if (isError) return <ErrorCard refetch={refetch} />;

	return (
		<DashboardCard
			icon={<Icons.Paid variant='outlined' style={{ fontSize: '1.5rem' }} />}
			title='Saldo'
			addon={
				<CircleButton
					variant='default'
					type='text'
					onClick={toggleVisibility}
					loading={isPending}
					icon={
						isVisible ? (
							<Icons.Visibility variant='outlined' style={{ fontSize: '1.5rem' }} />
						) : (
							<Icons.VisibilityOff variant='outlined' style={{ fontSize: '1.5rem' }} />
						)
					}
				/>
			}
		>
			<Row>
				<Space direction='vertical' style={{ width: '100%' }}>
					<Space direction='vertical'>
						<Typography.Headline size='large' variant='default'>
							{isPending ? (
								<Skeleton.Input size='small' active />
							) : (
								<>{isVisible ? CurrencyFormatter.toBRL(data.total.saldo) : <>R$ {<Placeholder />}</>}</>
							)}
						</Typography.Headline>
						<Typography.Body size='small' variant='default'>
							{isPending ? null : <>Resgate {isVisible ? data.total.resgate : <Placeholder />}%</>}
						</Typography.Body>
					</Space>
				</Space>
			</Row>

			<Row style={{ flexGrow: 1, gap: '0.5rem', alignItems: 'center' }}>
				<Col flex='auto' className={styles.col}>
					<SaldoCard
						variant='tertiary'
						title='Participante'
						icon={<Icons.Person variant='outlined' style={{ fontSize: '1.25rem' }} fill />}
						data={data?.participante}
						isVisible={isVisible}
						isLoading={isPending}
					/>
				</Col>
				<Col flex='auto' className={styles.col}>
					<SaldoCard
						variant='laranja'
						title='Portabilidade'
						icon={<Icons.CompareArrows variant='outlined' style={{ fontSize: '1.25rem' }} fill />}
						data={data?.portabilidade}
						isVisible={isVisible}
						isLoading={isPending}
					/>
				</Col>
				<Col flex='auto' className={styles.col}>
					<SaldoCard
						variant='magenta'
						title='Patrocinadora'
						icon={<Icons.CorporateFare variant='outlined' style={{ fontSize: '1.25rem' }} fill />}
						data={data?.patrocinadora}
						isVisible={isVisible}
						isLoading={isPending}
					/>
				</Col>
			</Row>

			<Row style={{ justifyContent: 'end' }}>
				<Col>
					<Button
						variant='default'
						type='filled-tonal'
						onClick={(e) => navigateOnClick(e, 'saldo')}
						loading={isPending}
					>
						Consultar saldo detalhado
					</Button>
				</Col>
			</Row>
		</DashboardCard>
	);
};
