import axios, { CreateAxiosDefaults } from 'axios';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// TODO - Atualizar react query (não é o @tanstack)

import { checkObjectForProperty, checkValueForType } from '../helpers';

export type ApiConfig = {
	url: string;
	clienteId: number | string;
};

export type KeycloakConfig = {
	url: string;
	realm: string;
	adminRealm: string;
	clientId: string;
};

export type GlobalConfig = {
	keycloakConfig: KeycloakConfig;
	apiConfig: ApiConfig;
};

const validateGlobalConfig = (config: any) => {
	// Função que checa a existência de uma lista de propriedades de um objeto
	const validateConfig = (config: object, properties: string[]) => {
		return properties.every((property) => checkObjectForProperty(config, property));
	};

	// Propriedades esperadas dentro do arquivo de config
	const expectedProperties = [
		{ name: 'keycloakConfig', properties: ['url', 'realm', 'adminRealm', 'clientId'] },
		{ name: 'apiConfig', properties: ['url', 'clienteId'] },
	];

	expectedProperties.forEach(({ name, properties }) => {
		// Checa se as props existem
		if (!checkObjectForProperty(config, name)) {
			throw new Error(`[useGlobalConfigFile] ${name} ausente no arquivo de configuração!`);
		}

		const obj = config[name];

		// Checa se são objetos
		if (!checkValueForType(obj, 'object')) {
			throw new Error(`[useGlobalConfigFile] ${name} não é um objeto!`);
		}

		// Checa se as props existem dentro do objeto
		if (!validateConfig(obj, properties)) {
			throw new Error(`[useGlobalConfigFile] Configuração ${name} com formato inválido!`);
		}
	});

	return true;
};

export const useGlobalConfigFile = () => {
	const globalConfig = useMemo<GlobalConfig>(() => {
		// Variável definida em '/public/config.js'
		// @ts-ignore
		if (!validateGlobalConfig(publicAppConfig)) throw new Error('Arquivo de configuração inválido!');

		// @ts-ignore
		return publicAppConfig;
	}, []);

	return globalConfig;
};

export const useApi = <T extends unknown>(options: CreateAxiosDefaults<T>) => {
	return axios.create(options);
};

export const useLocalStorage = <T extends unknown>(key: string, defaultValue: T) => {
	const storedValue = localStorage.getItem(key) as T;

	if (defaultValue && !storedValue) {
		localStorage.setItem(key, defaultValue as string);
	}

	const setValue = useCallback(
		(newValue: T) => {
			localStorage.setItem(key, newValue as string);
		},
		[key],
	);

	return [storedValue ?? defaultValue, setValue] as [T, (newValue: T) => void];
};

export const useNavigateOnClick = () => {
	const navigate = useNavigate();

	const navigateOnClick = (e: React.MouseEvent, path: string) => {
		e.stopPropagation();

		return navigate(path);
	};

	return navigateOnClick;
};
