import { NotFound } from 'jcm-design-system';
import { Route, Routes } from 'react-router-dom';

import { KeycloakContextProvider } from '../store';

import { LayoutAdmin, LayoutHome, LayoutParticipante } from '../layouts';
import { Admin, Home, Participante } from '../pages';

const ParticipanteElement: React.FC<{}> = () => {
	return <KeycloakContextProvider children={<LayoutParticipante />} />;
};

const AdminElement: React.FC<{}> = () => {
	return <KeycloakContextProvider admin children={<LayoutAdmin />} />;
};

export const AppRoutes: React.FC<{}> = () => {
	return (
		<Routes>
			{/* Rotas desprotegidas */}
			<Route path='/' element={<LayoutHome />}>
				<Route index element={<Home />} />
			</Route>

			{/* Rotas protegidas */}
			{/* ROTA DA PÁGINA ADMIN */}
			<Route path='/admin' element={<AdminElement />}>
				<Route index element={<Admin />} />
				<Route path='*' element={'Isso é uma página de administrador!'} />
			</Route>

			{/* ROTA DA PÁGINA PARTICIPANTE */}
			<Route path='/participante' element={<ParticipanteElement />}>
				<Route index element={<Participante />} />
				<Route path='*' element={'Isso é uma página de participante!'} />
			</Route>

			<Route path='*' element={<NotFound style={{ minHeight: '100vh' }} />} />
		</Routes>
	);
};
