import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App as AntApp } from 'antd';
import { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './routes';
import { ThemeContextProvider } from './store';

import './assets/scss/style.scss';

const App = () => {
	const queryClient = useMemo(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						retry: 0,
					},
				},
			}),
		[],
	);

	return (
		<AntApp>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<ThemeContextProvider>
						<AppRoutes />
					</ThemeContextProvider>
					<ReactQueryDevtools initialIsOpen={false} position='bottom' />
				</QueryClientProvider>
			</BrowserRouter>
		</AntApp>
	);
};

export default App;
