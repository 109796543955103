import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useApi, useGlobalConfigFile } from '..';
import { ParticipanteApi } from '../../classes';
import { IKeycloakContext, useKeycloakContext } from '../../context';

type ApiQueryErrorType = AxiosError;

const useParticipanteApi = (kcContext: IKeycloakContext, isProduction?: boolean) => {
	const { apiConfig } = useGlobalConfigFile();

	const axiosApi = useApi({ baseURL: apiConfig.url, headers: { Authorization: `Bearer ${kcContext.accessToken}` } });

	return new ParticipanteApi({
		api: axiosApi,
		clienteId: apiConfig.clienteId,
		isProduction,
	});
};

// Queries
type QueryOptions = { isProduction?: boolean };

// Contribuições
export const useTotaisContribuicoesQuery = (id: string | number, keys: any[] = [], options?: QueryOptions) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options?.isProduction);

	return useQuery<Awaited<ReturnType<typeof participanteApi.getTotaisContribuicoesById>>, ApiQueryErrorType>({
		queryKey: ['totaisContribuicoes', kcContext.user?.sub, id, ...keys],
		queryFn: () => participanteApi.getTotaisContribuicoesById(id),
		enabled: kcContext.isAuthenticated,
	});
};

export const useContribuicoesQuery = (id: string | number, keys: any[] = [], options?: QueryOptions) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options?.isProduction);

	return useQuery<Awaited<ReturnType<typeof participanteApi.getContribuicoesById>>, ApiQueryErrorType>({
		queryKey: ['contribuicoes', kcContext.user?.sub, id, ...keys],
		queryFn: () => participanteApi.getContribuicoesById(id),
		enabled: kcContext.isAuthenticated,
	});
};

// TODO - Implementar
export const useIdentidadeQuery = (keys: string[] = [], options?: QueryOptions) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options?.isProduction);

	return useQuery<Awaited<ReturnType<typeof participanteApi.getIdentidade>>, ApiQueryErrorType>({
		queryKey: ['identidade', kcContext.user?.sub, ...keys],
		queryFn: participanteApi.getIdentidade,
		enabled: kcContext.isAuthenticated,
	});
};

// Saldo
export const useSaldoQuery = (keys: string[] = [], options?: QueryOptions) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options?.isProduction);

	return useQuery<Awaited<ReturnType<typeof participanteApi.getSaldo>>, ApiQueryErrorType>({
		queryKey: ['saldo', kcContext.user?.sub, ...keys],
		queryFn: participanteApi.getSaldo,
		enabled: kcContext.isAuthenticated,
	});
};
