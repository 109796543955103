import { Col } from 'antd';
import { Button, Surface, Typography } from 'jcm-design-system';

import { DashboardCard } from './DashboardCard';

import avestruz from '../../../assets/images/avestruz.svg';

type Props = { id?: string; refetch: () => any };
export const ErrorCard: React.FC<Props> = ({ id, refetch }) => {
	return (
		<DashboardCard id={id}>
			<Col
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '1rem',
				}}
			>
				<Surface variant='default' level={1} style={{ width: '100%', textAlign: 'center', padding: '1rem' }}>
					<img src={avestruz} alt='' id='img-avestruz' />
				</Surface>

				<Typography.Title variant='error' size='medium'>
					Algo deu errado!
				</Typography.Title>

				<Button variant='default' type='filled-tonal' onClick={() => refetch()} id='btn-tentar-novamente'>
					Tentar novamente
				</Button>
			</Col>
		</DashboardCard>
	);
};
