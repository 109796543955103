import {
	GetContribuicoesReturnType,
	GetSaldoReturnType,
	GetTotaisContribuicoesReturnType,
	IParticipanteApi,
} from '../Api';

// Mock data
export const mockGetTotaisContribuicoesSuccessData: GetTotaisContribuicoesReturnType = {
	totalContribuicaoUltimoMes: 500,
	limiteRestanteBeneficioFiscal: 1500,
};

export const mockGetContribuicoesSuccessData: GetContribuicoesReturnType = {
	contribuicoesParticipante: { nome: 'nome', percentual: 10, valor: 1000 },
	contribuicoesPatrocinadora: { nome: 'nome', percentual: 10, valor: 1000 },
};

export const mockGetSaldoSuccessData: GetSaldoReturnType = {
	total: { saldo: 20000, resgate: 60 },
	participante: { saldo: 10000, resgate: 50 },
	patrocinadora: { saldo: 7500, resgate: 25 },
	portabilidade: { saldo: 2500, resgate: 10 },
};

// Classes
export class ParticipanteApi implements IParticipanteApi {
	public getIdentidade = async () => {
		throw new Error('Não implementado!');
	};

	public getContribuicoesById = async (id: string | number) => {
		return mockGetContribuicoesSuccessData;
	};

	public getTotaisContribuicoesById = async (id: string | number) => {
		return mockGetTotaisContribuicoesSuccessData;
	};

	public getSaldo = async () => {
		return mockGetSaldoSuccessData;
	};
}
