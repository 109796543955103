import { ThemeContextProvider as DSThemeContextProvider, Themes } from 'jcm-design-system';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DefaultTheme } from 'styled-components';

import { useLocalStorage } from '../hooks';

type ThemeVariant = 'light' | 'dark';

export interface IThemeContext {
	currentTheme: ThemeVariant;
	toggleTheme: () => void;
}

const ThemeContext = createContext<IThemeContext>(undefined!);

export const useThemeContext = () => useContext(ThemeContext);

type Props = { children: React.ReactNode; overrides?: { light?: Partial<DefaultTheme>; dark?: Partial<DefaultTheme> } };
export const ThemeContextProvider: React.FC<Props> = ({ children, overrides }) => {
	// Puxamos o valor inicial da localstorage
	const [preferredTheme, setPreferredTheme] = useLocalStorage<ThemeVariant>('preferredTheme', 'light');
	const [currentTheme, setCurrentTheme] = useState<ThemeVariant>(preferredTheme);

	// Sempre que o tema mudar, salva no localstorage
	useEffect(() => {
		return setPreferredTheme(currentTheme);
	}, [currentTheme, setPreferredTheme]);

	const toggleTheme = useCallback(() => {
		setCurrentTheme((current) => (current === 'dark' ? 'light' : 'dark'));
	}, []);

	const theme = useMemo(() => {
		const lightTheme = { ...Themes.getLightTheme(), ...overrides?.light };
		const darkTheme = { ...Themes.getDarkTheme(), ...overrides?.dark };

		return currentTheme === 'light' ? lightTheme : darkTheme;
	}, [currentTheme, overrides?.light, overrides?.dark]);

	const context: IThemeContext = { currentTheme, toggleTheme };
	return (
		<ThemeContext.Provider value={context}>
			<DSThemeContextProvider theme={theme}>{children}</DSThemeContextProvider>
		</ThemeContext.Provider>
	);
};
